import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import plusIcon from "@iconify/icons-fe/plus";

export class AddTodo extends Component {
  state = {
    title: "",
  };

  onChange = (e) => {
    this.setState({ title: e.target.value }); // can use e.target.name instead of 'title' directly to match the wanted field w/o creating tons of onChange
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.addTodo(this.state.title);
    this.setState({ title: "" });
  };

  render() {
    return (
      <form onSubmit={this.onSubmit} action="" style={addTodoStyle}>
        <input
          type="text"
          name="title"
          id="title"
          placeholder="Add todo ..."
          style={formStyle}
          value={this.state.title}
          onChange={this.onChange}
        />
        <button type="submit" style={submitStyle}>
          <Icon icon={plusIcon} />
        </button>
        {/* <input type="submit" value="Submit" className="btn" /> */}
      </form>
    );
  }
}

// PropTypes
AddTodo.propTypes = {
  addTodo: PropTypes.func.isRequired,
};

const addTodoStyle = {
  display: "flex",
  background: "#4E74A4",
  borderRadius: "5px",
  padding: "0.3em 1em",
  marginBottom: "3em",
};

const formStyle = {
  flex: "10",
  border: "none",
  background: "inherit",
  color: "#FFFFFF",
};

const submitStyle = {
  // background: "#4E74A4",
  background: "inherit",
  border: "none",
  flex: "1",
  paddingTop: "5px",
  color: "#dcf2ff",
  fontSize: "24px",
  cursor: "pointer",
};

export default AddTodo;
