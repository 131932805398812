import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/layout/Header";
import Todos from "./components/Todos";
import AddTodo from "./components/AddTodo";
import About from "./components/pages/About";
// import { v4 as uuid } from "uuid";
import Axios from "axios";

import "./App.css";

class App extends React.Component {
  state = {
    todos: [],
  };

  componentDidMount() {
    localStorage.removeItem("id");
    localStorage.setItem("id", "10");
    Axios.get("https://jsonplaceholder.typicode.com/todos?_limit=10").then(
      (res) => {
        this.setState({ todos: res.data });
      }
    );
  }

  // Toggle Complete
  markComplete = (id) => {
    this.setState({
      todos: this.state.todos.map((todo) => {
        if (todo.id === id) {
          todo.completed = !todo.completed;
        }
        return todo;
      }),
    });
  };

  // Delete Todo Item
  delTodo = (id) => {
    Axios.delete(`https://jsonplaceholder.typicode.com/todos/${id}`).then(
      (res) =>
        this.setState({
          todos: [...this.state.todos.filter((todo) => todo.id !== id)],
        })
    );
  };

  // Add Todo Item
  addTodo = (title) => {
    /* const newTodo = {
      title,
      id: uuid(),
      completed: false,
    };
    this.setState({ todos: [...this.state.todos, newTodo] }); // create copy of todos state with additions */

    Axios.post("https://jsonplaceholder.typicode.com/todos", {
      title,
      completed: false,
    }).then((res) => {
      let localId = localStorage.getItem("id");
      localId++;
      res.data.id = Number(localId);
      localStorage.setItem("id", localId);
      this.setState({ todos: [...this.state.todos, res.data] });
      // console.log(`${localId}`);
      // TODO: Genrate an ID for the shit
    });
  };

  render() {
    return (
      <Router>
        <div className="App">
          <div className="container">
            <Header />
            <Route
              exact
              path="/"
              render={(props) => (
                <React.Fragment>
                  <AddTodo addTodo={this.addTodo} />
                  <Todos
                    todos={this.state.todos}
                    markComplete={this.markComplete}
                    delTodo={this.delTodo}
                  />
                </React.Fragment>
              )}
            />
            <Route path="/about" component={About} />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
