import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import trashIcon from "@iconify/icons-fe/trash";

import "./TodoItem.css";

export class TodoItem extends Component {
  getStyle = () => {
    return {
      color: "#F5FAFF",
      padding: "16px",
      textDecoration: this.props.todo.completed ? "line-through" : "none",
    };
  };

  check = (completed) => {
    const box = document.getElementsByTagName("input");
    if (this.completed === true) {
      box.checked = true;
    }
  };

  render() {
    const { id, title, completed } = this.props.todo;
    return (
      <div style={this.getStyle()}>
        <p style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ flex: "20" }}>
            <input
              type="checkbox"
              name=""
              id=""
              defaultChecked={completed ? true : false}
              onChange={this.props.markComplete.bind(this, id, title)}
            />
            <span style={{ margin: "auto 1em" }}>{title}</span>
          </span>
          <button onClick={this.props.delTodo.bind(this, id)} style={btnStyle}>
            <Icon
              icon={trashIcon}
              style={{ color: "#e9a0b8", fontSize: "24px" }}
            />
          </button>
        </p>
      </div>
    );
  }
}

// PropTypes
TodoItem.propTypes = {
  todo: PropTypes.object.isRequired,
  markComplete: PropTypes.func.isRequired,
  delTodo: PropTypes.func.isRequired,
};

// Button styling
const btnStyle = {
  background: "inherit",
  border: "none",
  cursor: "pointer",
  float: "right",
  flex: "1",
};

export default TodoItem;
